import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import './thank-you.scss';

function getThankYouMessage(query) {
  switch(query) {
    case 'file':
      return {
        title: "Thank you for filling out your information",
        message: ["A link to the file has been sent to your email."]
      };
    case 'quiz':
      return {
        title: "Thank you for completing the Capital Partners 'Investment Risk Assessment Questionnaire'.",
        message: [
          'The full report has been emailed to your designated email address, and a copy has also been sent to your Adviser.',
          'WHAT HAPPENS NEXT...?',
          'The results alone do not determine your investment preferences; instead we will review your answers and use this information in conjunction with your goals and objectives to determine an appropriate investment portfolio asset allocation.'
        ]
      };
    default:
      return {
        title: "Thank you for filling out your information",
        message: ["We will get in touch with you shortly."]
      };
  }
}

const ThankYouPage = (props) => {
  const {
    location: {
      search: queryString = null
    }
  } = props;
  const queryStringArray = queryString.substring(queryString.indexOf('?')+1).split('&');
  const queryValue = queryStringArray[0].split('=')[1];
  const thankYouMessage = getThankYouMessage(queryValue);
  return (
    <Layout className="thank-you-page">
      <SEO title="Thank You | Capital Partners" />
      <div className="wrap">
        <div className="thank-you-message">
          <h2 dangerouslySetInnerHTML={{__html: thankYouMessage.title}} />
          {thankYouMessage.message.map(paragraph => <p dangerouslySetInnerHTML={{__html: paragraph}} />)}
        </div>
      </div>
    </Layout>
  );
}

export default ThankYouPage
